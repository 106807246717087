import React from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
} from 'react-native'
import moment from 'moment'
// import CfImage from './cfImage'
// import {Colors} from '../constants/colors'

export default class FeatureNewsCard extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    newsSource: PropTypes.string.isRequired,
    image: PropTypes.string,
    url: PropTypes.string.isRequired,
    onPressItem: PropTypes.func.isRequired,
    publishedAt: PropTypes.string.isRequired,
    activeOpacity: PropTypes.number,
    firebaseDocumentKey: PropTypes.string.isRequired,
  }

  static defaultProps = {
    image: null,
    activeOpacity: 1,
  }

  render() {
    const {
      newsSource,
      image,
      title,
      url,
      publishedAt,
      activeOpacity,
      firebaseDocumentKey,
      onPressItem,
      onLongPressItem,
    } = this.props

    let [newsTitle, ] = title.split(' - ')

    return (
      <TouchableOpacity
        delayPressIn={50}
        activeOpacity={activeOpacity}
        onPress={() => onPressItem(url, newsTitle, firebaseDocumentKey)}
        onLongPress={() => {
          onLongPressItem(url, newsTitle, firebaseDocumentKey)
        }}
        style={{
          marginVertical: 6,
          minHeight: 220,
        }}
      >
        <View style={{
          flex: 1,
        }}>
          <Image
            styles={styles.image}
            source={{
              uri: image,
              width: '100%',
              height: '100%',
              minHeight: 220,
            }}
          />
          <View style={{
            flex: 1,
            flexDirection: 'row',
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}>
            <View style={{
              flexDirection: 'column',
              flex: 1,
              paddingHorizontal: 16,
              paddingTop: 8,
              paddingBottom: 8,
              backgroundColor: 'rgba(0, 0, 2, 0.6)',
            }}>
              <Text
                numberOfLines={3}
                style={{
                  fontSize: 16,
                  lineHeight: 22,
                  fontWeight: '600',
                  marginBottom: 4,
                  color: '#FFFFFF',
                }}
              >
                {newsTitle.trim()}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  fontSize: 10,
                  fontWeight: '400',
                  color: '#DCD8D8',
                  marginLeft: -2,
                }}
              >
                {`${newsSource.trim()} / ${moment(publishedAt).fromNow(true)}`}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    height: 220,
    resizeMode: 'cover',
  },
})
