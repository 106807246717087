import React, {useState, useEffect} from 'react'
import {View, Image, FlatList} from 'react-native'
import _ from 'lodash'

import * as dataService from '../services/data'
import NewsCard from '../components/newsCard'
import config from '../config'

const colors = {
  blue: '#2C72C8',
  green: '#40A282',
  black: '#2E3137',
  white: '#FFFFFF',
  textBlack: '#333333',
  textGray: '#9E9E9E',
  textWhite: '#FFFFFF',
  lightGray: '#DFE1E5',
  gray: '#B6BCC0',
  red: '#D44367',
  outRed: '#d9534f',
  backgroundGray: '#E4E3E8',
  backgroundWhite: '#F8F8FA',
  backgroundPureWhite: '#FFFFFF',
  shadowBorder: '#EEEEEE',
  baseballFieldGroundColor: '#D37552',
}

const IndexView = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [articles, setArticles] = useState([])

  useEffect(() => {
    setLoading(true)
    dataService.getLatestNews()
      .then(querySnapshot => {
        const articleArray = []
        querySnapshot.forEach((doc) => {
          articleArray.push(doc.data())
        })
        setArticles(articleArray)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setLoading(false)
        setError('fetch failed')
      })
  }, [])

  if (loading) {
    return <p>loading..</p>
  }

  if (error !== '') {
    return <p>ERROR: {error}</p>
  }

  const onPressNewsItem = (url, title, firebaseDocumentKey) => {
    // CfTracking.logEvent('Index_clicked_news', {
    //   title,
    //   firebaseDocumentKey,
    // })

    // let collection = 'news'
    // if (this.locale === 'en-us') {
    //   collection = 'news-english'
    // }

    try {
      dataService.updateNewsReadCount({
        collection: 'news',
        docId: firebaseDocumentKey,
      })
    } catch (error) {
      console.log(error)
    }

    window.open(url, '_blank')
  }

  const visibleNews = Object.values(articles).filter(item => !item.isHide)

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.backgroundPureWhite,
      }}
    >
      <View
        elevation={2}
        style={
          {
            backgroundColor: colors.backgroundPureWhite,
            paddingVertical: 16,
            overflow: 'visible',
            borderColor: colors.shadowBorder,
            borderBottomWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderWidth: 1,
            flex: 1,
            position: 'fixed',
            zIndex: 10,
            width: '100%',
            maxWidth: 900,
          }
        }
      >
        <View style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {/* <Text style={{
            fontSize: 18,
            color: Colors.blue,
          }}>TW Baseball Fans</Text> */}
          <Image
            style={{
              width: 200,
              height: 30,
            }}
            source={{
              uri: `${config.REACT_APP_CDN_IMAGE_URL}/web/logo-word.png`,
              height: 140,
            }}
          />
        </View>

      </View>
      <FlatList
        style={{
          flex: 1,
          marginTop: 64,
        }}
        // style={styles.newsWrapper}
        // onScroll={this.handleNewsScroll}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={1}
        // refreshControl={
        //   <RefreshControl
        //     refreshing={refreshing}
        //     onRefresh={this.fetchNewsAndVideo}
        //     tintColor={colors.blue}
        //     colors={[colors.blue]}
        //   />
        // }
        keyExtractor={(article) => article.newsId}
        data={visibleNews}
        renderItem={({item, index}) => {
          const article = item

          return (
            <NewsCard
              title={_.get(article, 'title', null)}
              newsSource={_.get(article, 'newsSource', '')}
              publishedAt={_.get(article, 'publishedAt', null)}
              image={_.get(article, 'urlToImage', null)}
              url={article.url}
              firebaseDocumentKey={article.newsId}
              isFeatureMode={index % 5 === 1}
              read={_.get(article, 'read', 0)}
              onPressItem={onPressNewsItem}
              onLongPressItem={() => {}}
            />
          )
        }}
      />
    </View>
  )
}

export default IndexView
