import React from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
} from 'react-native'
import moment from 'moment'
// import {Colors} from '../constants/colors'
import FeatureNewsCard from './featureNewsCard'
// import CfImage from './cfImage'
import {isProduction} from '../utils'
// import {AppContext} from '../AppContextProvider'

// import {Colors} from 'cpblFans/constants/colors'
const colors = {
  blue: '#2C72C8',
  green: '#40A282',
  black: '#2E3137',
  white: '#FFFFFF',
  textBlack: '#333333',
  textGray: '#9E9E9E',
  textWhite: '#FFFFFF',
  lightGray: '#DFE1E5',
  gray: '#B6BCC0',
  red: '#D44367',
  outRed: '#d9534f',
  backgroundGray: '#E4E3E8',
  backgroundWhite: '#F8F8FA',
  backgroundPureWhite: '#FFFFFF',
  shadowBorder: '#EEEEEE',
  baseballFieldGroundColor: '#D37552',
}

export default class NewsCard extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    newsSource: PropTypes.string.isRequired,
    image: PropTypes.string,
    url: PropTypes.string.isRequired,
    onPressItem: PropTypes.func.isRequired,
    onLongPressItem: PropTypes.func.isRequired,
    publishedAt: PropTypes.string.isRequired,
    activeOpacity: PropTypes.number,
    firebaseDocumentKey: PropTypes.string.isRequired,
    isFeatureMode: PropTypes.bool,
    read: PropTypes.number.isRequired,
  }

  static defaultProps = {
    image: null,
    activeOpacity: 1,
    isFeatureMode: false,
  }

  // static contextType = AppContext

  render() {
    // const {theme: colors} = this.context
    const {
      newsSource,
      image,
      title,
      url,
      publishedAt,
      activeOpacity,
      firebaseDocumentKey,
      isFeatureMode,
      read,
      onPressItem,
      onLongPressItem,
    } = this.props

    const normalisedSource = !image || !image.split('http')[1] ? null : image

    if (isFeatureMode && normalisedSource !== null) {
      return <FeatureNewsCard
        {...this.props}
      />
    }

    let [newsTitle, ] = title.split(' - ')

    return (
      <TouchableOpacity
        delayPressIn={50}
        activeOpacity={activeOpacity}
        onPress={() => onPressItem(url, newsTitle, firebaseDocumentKey)}
        onLongPress={() => {
          onLongPressItem(url, newsTitle, firebaseDocumentKey)
        }}
        style={{
          marginVertical: 6,
          minHeight: 150,
        }}
      >
        <View style={{
          flex: 1,
          flexDirection: 'row',
          paddingHorizontal: 16,
          paddingVertical: 8,
        }}>
          <View style={{
            flexDirection: 'column',
            flex: 6,
            marginRight: 16,
            paddingBottom: 16,
          }}>
            <Text
              numberOfLines={3}
              style={{
                fontSize: 18,
                lineHeight: 24,
                fontWeight: '400',
                marginBottom: 8,
                color: colors.textBlack,
              }}
            >
              {newsTitle}
            </Text>
            <View style={{
              flexDirection: 'row',
            }}>
              <Text
                numberOfLines={1}
                style={{
                  fontSize: 14,
                  fontWeight: '300',
                  color: colors.textGray,
                }}
              >
                {`${newsSource.trim()} / `}
                <Text
                  style={[
                    {
                      fontSize: 14,
                      fontWeight: '300',
                      color: colors.textGray,
                      marginLeft: -2,
                    },
                    moment().unix() - moment(publishedAt).unix() < 172800 && {
                      color: colors.green,
                    },
                  ]}
                >
                  {` ${moment(publishedAt).fromNow(true)}`}
                </Text>
                {isProduction === false && read > 0 && (
                  <Text style={{
                    fontSize: 14,
                    fontWeight: '300',
                    color: colors.textGray,
                  }}>{` (${read})`}</Text>
                )}
              </Text>
            </View>
          </View>
          <View style={{flex: 3}}>
            <Image
              source={{
                uri: image,
                width: '100%',
                height: '100%',
                minHeight: 140,
              }}
              styles={styles.image}
            />
            {/* <CfImage
              styles={styles.image}
              source={image}
            /> */}
          </View>
        </View>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    height: 70,
    borderRadius: 4,
    resizeMode: 'cover',
  },
})
