import 'firebase/firestore'
import firebase from './firebase'

const db = firebase.firestore()

export const authenticateAnonymously = () => {
  return firebase.auth().signInAnonymously()
}

export const getLatestNews = () => {
  return db
    .collection('news')
    .orderBy('publishedAt', 'desc')
    .limit(50)
    .get()
  }

export const updateNewsReadCount = ({collection, docId}) => {
  return db
    .collection(collection)
    .doc(docId)
    .update({
      read: firebase.firestore.FieldValue.increment(1)
    })
}
